//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-8:_1048,_5192,_2596,_4320,_8672,_1392,_2012,_7812;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-8')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-8', "_1048,_5192,_2596,_4320,_8672,_1392,_2012,_7812");
        }
      }catch (ex) {
        console.error(ex);
      }